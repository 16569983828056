import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Worker from "../images/logo.png"
import Instagram from "../images/instagram.svg"
import Facebook from "../images/facebook.svg"

const Footer = ({ data }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          allDatoCmsServicio(sort: { order: ASC, fields: ordenDeServicio }) {
            nodes {
              titulo
              slug
            }
          }
        }
      `}
      render={data => (
        <div className="w-full bg-black pt-10 pb-10 lg:pt-20 lg:pb-20">
          <div className="w-full lg:flex lg:w-10/12 mx-auto">
            <div className="w-full lg:w-auto text-center lg:text-left">
              <img
                src={Worker}
                alt="Spiritual Worker"
                className="mx-auto"
                style={{ maxWidth: "120px" }}
              />
            </div>
            <div className="w-full lg:w-auto px-6 lg:px-20 py-10 lg:pt-0">
              <ul>
                {data.allDatoCmsServicio.nodes.map(servicio => (
                  <li className="my-3" key={servicio.slug}>
                    <Link
                      className="text-gray-200 hover:underline"
                      to={`/${servicio.slug}/`}
                    >
                      {servicio.titulo}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full lg:w-auto px-6 py-10 lg:pt-0">
              <div className="social-media">
                <a
                  href="https://www.instagram.com/spiritualworker"
                  target="_blank"
                  rel="noopener"
                  className="inline-block m-3 "
                >
                  <img
                    src={Instagram}
                    alt="Spiritual Worker Instagram"
                    style={{ width: 40 }}
                  />
                </a>
                <a
                  href="https://www.facebook.com/Gisellespiritualworker"
                  target="_blank"
                  rel="noopener"
                  className="inline-block m-3 "
                >
                  <img
                    src={Facebook}
                    alt="Facebook Worker Instagram"
                    style={{ width: 40 }}
                  />
                </a>
              </div>
              <p className="text-gray-200">
                Tel:{" "}
                <a href="tel:+50688355826" className="underline">
                  +506 8835-5826
                </a>
              </p>
              <p className="text-gray-200">
                Email:{" "}
                <a
                  href="mailto:giselle@spiritualworker.org"
                  className="underline"
                >
                  giselle@spiritualworker.org
                </a>
              </p>
              <p className="text-gray-200">
                Para citas escribir a Whatsapp al{" "}
                <a
                  className="whatsapp underline"
                  href="https://api.whatsapp.com/send?phone=50688355826&text=Contacto%20del%20sitio%20web"
                >
                  +506 8835 5826
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default Footer
