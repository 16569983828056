import React, { useState } from "react"
import { Link } from "gatsby"

const Navigation = ({}) => {
  const [isExpanded, toggleExpansion] = useState(false)
  const [isShown, toggleShown] = useState(false)

  return (
    <nav className="w-full flex items-center justify-between flex-wrap p-6 lg:pl-0 bg-gray-800 text-center sans">
      <div className="block text-center mx-auto lg:hidden">
        <button
          className="flex items-center px-3 py-2 border rounded text-gray-200 border-gray-400 hover:text-white hover:underline hover:border-white mx-auto"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          Menu
        </button>
      </div>
      <div
        className={`${
          isExpanded ? `block` : `hidden`
        } w-full block flex-grow lg:flex lg:items-center lg:w-auto nav-links transition duration-400 ease-in-out transform`}
      >
        <div className="lg:flex-grow md:items-center lg:items-center ml-10 pt-3 tracking-tight">
          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-200 text-lg hover:text-white hover:underline mr-8">
            <Link to="/" activeclassname="underline">
              Inicio
            </Link>
          </div>

          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-200 text-lg hover:text-white hover:underline mr-8">
            <Link to="/bio" activeclassname="underline">
              Bio
            </Link>
          </div>

          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-200 text-lg hover:text-white hover:underline mr-8 ">
            <button
              activeclassname="underline"
              onMouseEnter={() => toggleShown(!isShown)}
            >
              Servicios
            </button>

            <div
              className={`${
                isShown ? `hidden lg:block` : `block lg:hidden`
              }  block lg:absolute lg:bg-gray-800 lg:border lg:border-gray-500 lg:shadow-lg lg:rounded lg:p-5 lg:text-left`}
              onMouseLeave={() => toggleShown(!isShown)}
              style={{ zIndex: "100" }}
            >
              <Link
                to="/reiki-angelico/"
                className="block hover:underline my-2"
              >
                Reiki Angélico
              </Link>
              <Link
                to="/limpieza-y-armonizacion-energetica/"
                className="block hover:underline my-2"
              >
                Limpieza y armonización energética
              </Link>
              <Link to="/gemoterapia/" className="block hover:underline my-2">
                Gemoterápia
              </Link>
              <Link
                to="/terapia-de-regresion-y-vidas-pasadas/"
                className="block hover:underline my-2"
              >
                Terapia regresiva y vidas pasadas
              </Link>

              <Link
                to="/beyond-quantum-healing/"
                className="hidden hover:underline my-2"
              >
                Beyond Quantum Healing
              </Link>
              <Link
                to="/consejeria-personalizada/"
                className="block hover:underline my-2"
              >
                Consejería Personal
              </Link>
              <Link
                to="/tarot-y-oraculos-para-sanar/"
                className="block hover:underline my-2"
              >
                Tarot y Oráculos para sanar
              </Link>
            </div>
          </div>

          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-200 text-lg hover:text-white hover:underline mr-8 ">
            <Link to="/novedades/" activeclassname="underline">
              Novedades
            </Link>
          </div>

          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-200 text-lg hover:text-white hover:underline mr-8 ">
            <Link to="/blog/" activeclassname="underline">
              Blog
            </Link>
          </div>

          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-200 text-lg hover:text-white hover:underline mr-8 ">
            <Link to="/contacto/" activeclassname="underline">
              Contacto
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
