import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"

import Whatsapp from "../images/whatsapp.svg"

export const query = graphql`
  query($slug: String!) {
    datoCmsServicio(slug: { eq: $slug }) {
      titulo
      duraciNDelServicio {
        duracion
      }
      imagen {
        fluid {
          src
        }
      }
      imagenDePortada {
        fluid {
          src
        }
      }
      descripcion
      precio
      testimonios {
        testimonio
        nickname
      }
    }
  }
`

const Services = ({ data }) => {
  const contenido = data.datoCmsServicio
  console.log(contenido.testimonios)

  return (
    <Layout>
      <SEO title={contenido.titulo} />

      <div className="w-full mx-auto bg-gray-900 pt-5 pt-10 pb-10 lg:pt-20 lg:pb-20">
        <h2 className="titles text-gray-200 text-2xl lg:text-4xl text-center pb-5 lg:pb-20">
          {contenido.titulo}
        </h2>
        <div className="w-full lg:w-10/12 lg:flex mx-auto">
          <div className="w-full lg:w-1/3 rounded-lg p-5">
            <img
              src={contenido.imagenDePortada.fluid.src}
              alt={contenido.titulo}
              className=""
            />
            <p className="text-sm text-yellow-100 p-0 py-4">
              Duración estimada: {contenido.duraciNDelServicio.duracion}
            </p>
            <p className="hidden text-sm text-yellow-100 p-0 pt-0">
              Técnicas: Chamánicas con Péndulo Cuántico y cristales.
            </p>
            <p className="text-sm text-yellow-100 p-0 pt-0">
              Precio:{" "}
              {contenido.titulo === "Beyond Quantum Healing" ? "$" : "¢"}
              {contenido.precio}
            </p>
            <p className="text-sm text-yellow-100 p-0 pt-3">
              <a
                className="hover:underline"
                href={`https://api.whatsapp.com/send?phone=50688355826&text=Quiero%20una%20cita%20de%20${encodeURIComponent(
                  contenido.titulo
                )}`}
              >
                Agendar una cita{" "}
                <img
                  src={Whatsapp}
                  style={{ maxWidth: "23px", display: "inline-block" }}
                  alt=""
                />
              </a>
            </p>
          </div>

          <div
            className="w-full lg:w-2/3 mt-5 lg:mt-0 lg:mb-10"
            dangerouslySetInnerHTML={{ __html: contenido.descripcion }}
          />
        </div>

        {contenido.testimonios.length > 0 && (
          <div className="w-full md:w-2/3 mx-auto py-5">
            <h3 className="text-3xl text-gray-400">Testimonios:</h3>
          </div>
        )}

        {contenido.testimonios &&
          contenido.testimonios.map(testi => (
            <div className="testimonios w-full md:w-2/3 mx-auto">
              <div className="testimonio w-11/12 rounded-md bg-gray-800 mb-10 p-5 pb-10">
                <p className="text-white text-sm">{testi.nickname}:</p>
                <div
                  className="w-full testimonioquote p-3 m-0 mt-5"
                  dangerouslySetInnerHTML={{
                    __html: `<span class="text-white text-2xl">“</span>${testi.testimonio}<span class="text-white text-2xl">”</span>`,
                  }}
                />
              </div>
            </div>
          ))}
      </div>

      <Footer />
    </Layout>
  )
}

export default Services
